export const environment = {
  production: true,
  mock: false,
  mockLogin: false,
  apiUrl: 'https://weldguide.trumpf.com/api',

  // OIDC Settings
  oidcIssuer: 'https://identity.trumpf.com:443/oauth2/token',
  oidcClientId: 'sqqoLqiKHu1RAt0u4gWTo347hJQa',
  oidcRedirectUri: window.location.origin,
  oidcScope: 'openid profile given_name family_name locality groups',

  // Cad Api
  cadApiUrl: 'https://importer3d.azurewebsites.net/solidconversion/part/v1',
};
